<template>
  <v-app>
    <v-card class="bgss rounded-0 d-flex justify-center" flat width="100%" height="100%">
      <!-- 步骤一 -->
      <div class="mx-auto box transition-fast-in-fast-out px-2 px-sm-8" style flat v-if="tab == 1">
        <v-row class="my-0" align="center">
          <v-col cols="12" sm="5">
            <p class="font32 font-weight-bold mb-2">{{ $t("店铺类型") }}</p>
            <!-- <p class="mb-0 text-body-1 text--secondary">
              {{ $t("选择最适合你的类型和套餐，开启建店第一步！") }}
            </p>-->
          </v-col>
          <v-spacer />
          <v-col cols="12" sm="5" class="d-flex align-end justify-end">
            <p class="primary--text mb-0" style="font-size: 74px; font-weight: 900">1</p>
            <p class="primary--text mb-0" style="font-size: 44px; font-weight: 900">/4</p>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-card color="#fff" width="100%" height="16" class="rounded-pill" flat>
              <v-card width="25%" height="16" color="primary" flat class="rounded-r-0"></v-card>
            </v-card>
          </v-col>
          <v-col cols="12" class>
            <v-card
              width="100%"
              class="rounded-xl mt-4 px-5 py-6 py-md-12 px-md-10"
              color="#fff"
              flat
            >
              <v-row class="ma-0">
                <div>
                  <p class="mb-0 font24 font-weight-bold">{{ $t("商城站") }}</p>
                  <p class="mt-1 text--secondary">
                    {{
                    $t(
                    "不单是建立了一个网站，而是建立了一个完整的、自动履单免维护，并且可无限扩展升级的事业"
                    )
                    }}
                  </p>
                </div>
                <v-spacer />
                <!-- <v-card
                  class="d-flex align-center rounded-pill px-2"
                  height="63"
                  color="#F2F2F2"
                  flat
                >
                  <v-card
                    height="47"
                    style="line-height: 47px"
                    color="#F2F2F2"
                    flat
                    :class="
                      tab2Active.name == item.name
                        ? 'px-10 font18 primary rounded-pill white--text cursor'
                        : 'px-10 font18 rounded-pill cursor'
                    "
                    v-for="(item, index) in tab2Arr"
                    :key="index"
                    @click="tab2Active = item"
                  >
                    {{ item.name }}
                  </v-card>
                </v-card>-->
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-card min-height="302" color="#F7F7F7" class="px-7 py-11 rounded-xl" flat>
                    <v-img width="60" src="@/assets/images/login/icon1.png"></v-img>
                    <p class="my-5 font20 weight500">{{ $t("省心开店") }}</p>
                    <p class="mb-0 text--primary">
                      {{
                      $t(
                      "上百种精选电商主题模板一键应用，卡片式操作，无需设计能力，快速拥有一个能提现您品牌调性、安全、稳定的在线商店"
                      )
                      }}
                    </p>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-card min-height="302" color="#F7F7F7" class="px-7 py-11 rounded-xl" flat>
                    <v-img width="60" src="@/assets/images/login/icon2.png"></v-img>
                    <p class="my-5 font20 weight500">{{ $t("强大的应用生态") }}</p>
                    <p class="mb-0 text--primary">
                      {{
                      $t(
                      "丰富多元的应用市场，成熟完善的服务生态，提供您所需的选品供应、引流获客、物流履约、客户管理、功能拓展等多种工具和服务"
                      )
                      }}
                    </p>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-card min-height="302" color="#F7F7F7" class="px-7 py-11 rounded-xl" flat>
                    <v-img width="60" src="@/assets/images/login/icon3.png"></v-img>
                    <p class="my-5 font20 weight500">{{ $t("免费使用") }}</p>
                    <p class="mb-0 text--primary">
                      {{
                      $t(
                      "一站式工作台，多语言切换的操作模式，轻松管理商店的一切事务"
                      )
                      }}
                    </p>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="ma-0 mt-10" align="center">
                <router-link to="/" class="text--secondary text-body-1">
                  {{
                  $t("离开")
                  }}
                </router-link>
                <v-spacer />
                <div>
                  <v-btn
                    @click="tab = 2"
                    height="50"
                    x-large
                    class="primary-background px-12 rounded-pill white--text"
                  >{{ $t("下一步") }}</v-btn>
                </div>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <!-- 步骤2 -->
      <div class="mx-auto box transition-fast-in-fast-out px-2 px-sm-8" flat v-if="tab == 2">
        <v-row class="my-0" align="center">
          <v-col cols="12" sm="5">
            <p class="font32 font-weight-bold mb-2">{{ $t("店铺商品") }}</p>
            <p class="mb-0 text-body-1 text--secondary">{{ $t("超多热门商品包，无需上传，一键开启你的店铺销售") }}</p>
          </v-col>
          <v-spacer />
          <v-col cols="12" sm="5" class="d-flex align-end justify-end">
            <p class="primary--text mb-0" style="font-size: 74px; font-weight: 900">2</p>
            <p class="primary--text mb-0" style="font-size: 44px; font-weight: 900">/4</p>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-card color="#fff" width="100%" height="16" class="rounded-pill" flat>
              <v-card width="50%" height="16" color="primary" flat class="rounded-r-0"></v-card>
            </v-card>
          </v-col>
          <v-col cols="12" class>
            <v-card
              width="100%"
              class="rounded-xl mt-4 px-3 py-6 py-md-12 px-md-10"
              color="#fff"
              flat
            >
              <p class="mb-0 font24 font-weight-bold">{{ $t("店铺商品") }}</p>
              <p class="mt-1 text--secondary">
                {{
                $t(
                "请选择您想要进行售卖的商品种类，例如：日杂百货、原创服饰、鞋包配件、工厂直营等~"
                )
                }}
              </p>
              <v-card flat height="600" class="overflow-y pr-2">
                <v-row>
                  <v-col cols="6" sm="4" md="3" v-for="(n, index) in goodkuList" :key="index">
                    <v-hover v-slot="{ hover }">
                      <v-img
                        :attr="n.img"
                        class="rounded-xl"
                        :src="
                        n.img == ''
                          ? require('@/assets/images/login/goodnull.png')
                          : n.img
                      "
                      >
                        <v-row
                          class="ma-0 pt-3 mr-3"
                          style="position: absolute; right: 10px"
                          v-if="
                          check_good != '' &&
                          check_good.goods_package_id == n.goods_package_id &&
                          !hover
                        "
                        >
                          <v-spacer />
                          <v-card flat width="32" height="32" class="rounded-pill">
                            <v-img width="32" src="@/assets/images/login/checked.png"></v-img>
                          </v-card>
                        </v-row>
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            class="transition-fast-in-fast-out text-center v-card--reveal"
                            style="
                            height: 100%;
                            background: rgba(0, 0, 0, 0.6);
                            border-radius: 30px;
                          "
                          >
                            <v-row
                              class="ma-0 pt-3 mr-3"
                              style="position: absolute; right: 10px"
                              v-if="
                              check_good != '' &&
                              check_good.goods_package_id == n.goods_package_id
                            "
                            >
                              <v-spacer />
                              <v-card flat width="32" height="32" class="rounded-pill">
                                <v-img width="32" src="@/assets/images/login/checked.png"></v-img>
                              </v-card>
                            </v-row>
                            <div
                              class="d-flex justify-center align-center"
                              style="padding-top: 35px"
                            >
                              <div>
                                <p class="white--text mb-2">
                                  {{ $t("共") }}{{ n.goods_package_count
                                  }}{{ $t("种商品") }}
                                </p>
                                <v-btn
                                  v-if="
                                  check_good.goods_package_id !=
                                  n.goods_package_id
                                "
                                  class="white primary--text mx-auto"
                                  depressed
                                  @click="check_good = n"
                                >{{ $t("选择商品") }}</v-btn>
                                <v-btn
                                  v-else
                                  class="white primary--text mx-auto"
                                  depressed
                                  @click="check_good = ''"
                                >{{ $t("取消选择") }}</v-btn>
                                <p
                                  class="mb-0 grey--text text-center mt-3 cursor"
                                  @click="detail(index)"
                                >{{ $t("查看商品详情") }}</p>
                              </div>
                            </div>
                          </div>
                        </v-expand-transition>
                      </v-img>
                    </v-hover>
                    <p class="mb-0 text-center black--text mt-3">{{ n.name }}</p>
                  </v-col>
                </v-row>
              </v-card>
              <v-row class="ma-0 mt-10" align="center">
                <router-link to="/" class="text--secondary text-body-1">
                  {{
                  $t("离开")
                  }}
                </router-link>
                <v-spacer />
                <div>
                  <span
                    @click="tab = tab - 1"
                    class="cursor text-body-1 text--secondary mr-6"
                  >{{ $t("后退") }}</span>
                  <v-btn
                    v-if="check_good != ''"
                    @click="next(2)"
                    height="50"
                    x-large
                    class="primary-background px-12 rounded-pill white--text"
                  >{{ $t("下一步") }}</v-btn>
                  <v-btn
                    v-if="check_good == ''"
                    height="50"
                    x-large
                    disabled
                    style="cursor: not-allowed"
                    class="px-12 rounded-pill white--text"
                  >{{ $t("下一步") }}</v-btn>
                </div>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div
        class="mx-auto box transition-fast-in-fast-out"
        style="padding: 32px 0"
        flat
        v-if="tab == 3"
      >
        <v-row class="my-0" align="center">
          <v-col cols="12" sm="5">
            <p class="font32 font-weight-bold mb-2">{{ $t("店铺模板") }}</p>
            <p class="mb-0 text-body-1 text--secondary">
              {{
              $t(
              "上百种精选电商主题模板一键应用，卡片式操作，无需设计能力，快速拥有一个能提现您品牌调性、安全、稳定的在线商店"
              )
              }}
            </p>
          </v-col>
          <v-spacer />
          <v-col cols="12" sm="5" class="d-flex align-end justify-end">
            <p class="primary--text mb-0" style="font-size: 74px; font-weight: 900">3</p>
            <p class="primary--text mb-0" style="font-size: 44px; font-weight: 900">/4</p>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-card color="#fff" width="100%" height="16" class="rounded-pill" flat>
              <v-card width="75%" height="16" color="primary" flat class="rounded-r-0"></v-card>
            </v-card>
          </v-col>
          <v-col cols="12" class>
            <v-card
              width="100%"
              class="rounded-xl mt-4 px-3 py-6 py-md-12 px-md-10"
              color="#fff"
              flat
            >
              <p class="mb-0 font24 font-weight-bold">{{ $t("店铺模板") }}</p>
              <p class="mt-1 text--secondary">{{ $t("请选择您想要的模板~") }}</p>
              <v-row>
                <v-col cols="12" sm="6" md="4" v-for="(item, index) in themesList" :key="index">
                  <v-hover v-slot="{ hover }">
                    <v-img
                      height="500"
                      class="relative"
                      :src="
                        item.cover_img == ''
                          ? require('@/assets/images/login/cate.png')
                          : item.cover_img
                      "
                    >
                      <v-row
                        style="right: 20px; bottom: 20px"
                        class="ma-0 align-end absolute"
                        v-if="check_moban.themes_id == item.themes_id && !hover"
                      >
                        <v-spacer />
                        <v-card flat width="32" height="32" class="rounded-pill">
                          <v-img width="32" src="@/assets/images/login/checked.png"></v-img>
                        </v-card>
                      </v-row>
                      <v-expand-transition>
                        <div
                          v-if="hover"
                          class="transition-fast-in-fast-out text-center v-card--reveal relative"
                          style="
                            height: 100%;
                            background: rgba(0, 0, 0, 0.6);
                            border-radius: 30px;
                          "
                        >
                          <v-row
                            align="center"
                            style="width: 100%; height: 100%"
                            justify="center"
                            class="absolute"
                          >
                            <div>
                              <v-btn
                                v-if="check_moban.themes_id != item.themes_id"
                                class="white primary--text"
                                style
                                depressed
                                @click="check_moban = item"
                              >{{ $t("选择模板") }}</v-btn>
                              <v-btn
                                v-else
                                class="white primary--text"
                                style
                                depressed
                                @click="check_moban = ''"
                              >{{ $t("取消选择") }}</v-btn>
                              <div class="pt-5">
                                <a
                                  :href="item.link"
                                  target="_blank"
                                  class="mb-0 white--text text-center cursor text-decoration-none"
                                >{{ $t("查看模板详情") }}</a>
                              </div>
                            </div>
                          </v-row>
                          <v-row
                            style="right: 20px; bottom: 20px"
                            class="ma-0 align-end absolute"
                            v-if="check_moban.themes_id == item.themes_id"
                          >
                            <v-spacer />
                            <v-card flat width="32" height="32" class="rounded-pill">
                              <v-img width="32" src="@/assets/images/login/checked.png"></v-img>
                            </v-card>
                          </v-row>
                        </div>
                      </v-expand-transition>
                    </v-img>
                  </v-hover>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <div>
                    <v-img height="500" class="relative" src="@/assets/images/login/more.png">
                      <div
                        class="absolute mb-0 text-center"
                        style="bottom: 20px; width: 100%"
                      >{{ $t("更多模板敬请期待") }}~</div>
                    </v-img>
                  </div>
                </v-col>
              </v-row>
              <v-row class="ma-0 mt-10" align="center">
                <router-link to="/" class="text--secondary text-body-1">
                  {{
                  $t("离开")
                  }}
                </router-link>
                <v-spacer />
                <div>
                  <span
                    @click="tab = tab - 1"
                    class="cursor text-body-1 text--secondary mr-6"
                  >{{ $t("后退") }}</span>
                  <v-btn
                    v-if="check_moban != ''"
                    height="50"
                    x-large
                    @click="next(3)"
                    class="primary-background px-12 rounded-pill white--text"
                  >{{ $t("下一步") }}</v-btn>
                  <v-btn
                    v-else
                    height="50"
                    x-large
                    disabled
                    style="cursor: not-allowed"
                    class="px-12 rounded-pill white--text"
                  >{{ $t("下一步") }}</v-btn>
                </div>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div
        class="mx-auto box transition-fast-in-fast-out"
        style="padding: 32px 0"
        flat
        v-if="tab == 4"
      >
        <v-row class="my-0" align="center">
          <v-col cols="12" sm="5">
            <p class="font32 font-weight-bold mb-2">{{ $t("店铺信息") }}</p>
            <p class="mb-0 text-body-1 text--secondary">
              {{
              $t("编辑一个简洁好记的店铺信息，进行注册，方便用户进行检索商店")
              }}
            </p>
          </v-col>
          <v-spacer />
          <v-col cols="12" sm="5" class="d-flex align-end justify-end">
            <p class="primary--text mb-0" style="font-size: 74px; font-weight: 900">4</p>
            <p class="primary--text mb-0" style="font-size: 44px; font-weight: 900">/4</p>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-card color="#fff" width="100%" height="16" class="rounded-pill" flat>
              <v-card width="100%" height="16" color="primary" flat class="rounded-r-0"></v-card>
            </v-card>
          </v-col>
          <v-col cols="12" class>
            <v-card
              width="100%"
              class="rounded-xl mt-4 px-5 py-6 py-md-12 px-md-10"
              color="#fff"
              flat
            >
              <p class="mb-0 font24 font-weight-bold">{{ $t("店铺信息") }}</p>
              <v-divider class="my-3" />
              <p class="font18 weight500 mb-1">
                {{ $t("店铺名称")
                }}
                <span class="red--text">（{{ $t("必填项") }}）</span>
              </p>
              <p class="text--secondary mb-2">{{ $t("创建一个简单好记的店铺名称吧~他将会置于店铺顶部") }}</p>
              <v-card color="#F7F7F7" height="45" flat class="rounded-2">
                <input v-model="shopObj.name" :placeholder="$t('填写您的店铺名称')" />
              </v-card>
              <p class="font18 weight500 mb-1 mt-3">
                {{ $t("店铺域名")
                }}
                <span class="red--text">（{{ $t("必填项") }}）</span>
              </p>
              <p class="text--secondary mb-2">
                {{
                $t("设置一个简洁的前缀，方便用户检索商店~他将会置于店铺顶部")
                }}
              </p>

              <div flat class="rounded-2 d-flex align-center ipt-href">
                <div class="px-3 d-flex align-center ipt-href-left">
                  <b>http://</b>
                </div>

                <input
                  v-model="shopObj.path"
                  aria-autocomplete="false"
                  @input="checkWeb()"
                  :placeholder="$t('请输入字母或数字做为您的店铺域名')"
                />
                <div class="px-3 d-flex align-center ipt-href-right">
                  <b>.taoify.shop</b>
                </div>
              </div>
              <p v-if="isshowweb == 'error' && shopObj.path != ''" class="red--text mb-0 ml-4 mt-1">
                <v-icon color="red" small>mdi-alert-circle-outline</v-icon>
                {{ webmsg }}
              </p>
              <p
                v-if="isshowweb == 'success' && shopObj.path != ''"
                class="green--text mb-0 ml-4 mt-1"
              >
                <v-icon color="green" small>mdi-check-circle-outline</v-icon>
                {{ webmsg }}
              </p>
              <!-- 登录状态则不显示 -->
              <template v-if="$store.state.USERINFO==null">
                <p class="mt-6 mb-0 font24 font-weight-bold">{{ $t("个人信息") }}</p>
                <v-divider class="my-3" />
                <v-row class="mb-0">
                  <v-col cols="12" md="6" class="py-0">
                    <p class="font18 weight500 mb-1 mt-3">
                      {{ $t("电子邮件")
                      }}
                      <span class="red--text">（{{ $t("必填项") }}）</span>
                    </p>
                    <v-card color="#F7F7F7" height="45" flat class="rounded-2">
                      <input
                        v-model="shopObj.email"
                        autocomplete="new-password"
                        @input="checkEmail()"
                        :placeholder="$t('填写您的电子邮箱')"
                      />
                    </v-card>
                    <p
                      v-if="isshowemail == 'error' && shopObj.email != ''"
                      class="red--text mb-0 ml-4 mt-1"
                    >
                      <v-icon color="red" small>mdi-alert-circle-outline</v-icon>
                      {{ emailmsg }}
                    </p>
                    <p
                      v-if="isshowemail == 'success' && shopObj.email != ''"
                      class="green--text mb-0 ml-4 mt-1"
                    >
                      <v-icon color="green" small>mdi-check-circle-outline</v-icon>
                      {{ emailmsg }}
                    </p>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <p class="font18 weight500 mb-1 mt-3">
                      {{ $t("店铺密码")
                      }}
                      <span class="red--text">（{{ $t("必填项") }}）</span>
                    </p>
                    <v-card color="#F7F7F7" height="45" flat class="rounded-2">
                      <input
                        type="password"
                        autocomplete="new-password"
                        v-model="shopObj.pwd"
                        :placeholder="$t('填写您的店铺密码')"
                      />
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <p class="font18 weight500 mb-1 mt-3">
                      {{ $t("邮箱验证码")
                      }}
                      <span
                        class="red--text"
                      >（{{ $t("必填项") }}）</span>
                    </p>
                    <div class="d-flex justify-space-between">
                      <v-card width="100%" color="#F7F7F7" height="45" flat class="mr-3 rounded-2">
                        <input v-model="shopObj.code" :placeholder="$t('请输入邮箱验证码')" />
                      </v-card>
                      <v-card
                        flat
                        min-width="120"
                        height="45"
                        :disable="
                        isshowemail == 'success' && msg != '' ? false : true
                      "
                        style="line-height: 45px"
                        @click="getCode()"
                        :loading="sendloading"
                        :class="
                        isshowemail == 'success' && msg != ''
                          ? 'cursor px-3 text-center primary-background text-body-2 white--text'
                          : 'cursor px-3 text-center grey text-body-2 white--text'
                      "
                      >{{ msg == null ? " (" + countNum + "s)" : msg }}</v-card>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <p class="font18 weight500 mb-1 mt-3">
                      {{ $t("电话号码")
                      }}
                      <span class="red--text">（{{ $t("必填项") }}）</span>
                    </p>
                    <div class="d-flex">
                      <v-menu offset-y bottom v-model="menu" :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                          <v-card
                            v-bind="attrs"
                            v-on="on"
                            color="#F7F7F7"
                            height="45"
                            flat
                            min-width="130"
                            class="rounded-2 mr-3 d-flex align-center justify-space-between px-3 cursor"
                          >
                            <span>
                              {{
                              countryObj == ""
                              ? $t("区号")
                              : countryObj.country +
                              " +" +
                              countryObj.mobile_country_code
                              }}
                            </span>
                            <v-icon size="26">mdi-chevron-down</v-icon>
                          </v-card>
                        </template>
                        <v-card width="250" class="pa-3">
                          <v-card color="#F7F7F7" height="45" flat class="rounded-2">
                            <input
                              v-model="keyword"
                              @input="inputCountry()"
                              :placeholder="$t('搜索')"
                            />
                          </v-card>
                          <v-card height="200" class="mt-3" style="overflow: auto" flat outlined>
                            <v-list class="pa-0" dense>
                              <div
                                v-for="(item, index) in countryList"
                                :key="index"
                                @click="(countryObj = item), (menu = false)"
                              >
                                <v-list-item
                                  class="cursor"
                                >{{ item.country }} +{{item.mobile_country_code}}</v-list-item>
                                <v-divider />
                              </div>
                            </v-list>
                          </v-card>
                        </v-card>
                      </v-menu>
                      <v-card color="#F7F7F7" width="100%" height="45" flat class="rounded-2">
                        <input v-model="shopObj.phone" :placeholder="$t('填写您的电话号码')" />
                      </v-card>
                    </div>
                  </v-col>
                </v-row>
              </template>
              <v-row class="ma-0 mt-6">
                <v-checkbox v-model="istongyi" hide-details class="pa-0 ma-0"></v-checkbox>
                <p class="mb-0">
                  {{ $t("即表示您已同意")
                  }}
                  <a
                    target="_blank"
                    href="/register-agreement"
                    class="text-decoration-underline"
                  >{{ $t("条款与注册协议") }}</a>
                </p>
              </v-row>
              <v-row class="ma-0 mt-10" align="center">
                <router-link to="/" class="text--secondary text-body-1">
                  {{
                  $t("离开")
                  }}
                </router-link>
                <v-spacer />
                <div>
                  <span
                    @click="tab = tab - 1"
                    class="cursor text-body-1 text--secondary mr-6"
                  >{{ $t("后退") }}</span>
                  <template v-if="$store.state.USERINFO==null">
                    <v-btn
                      height="50"
                      x-large
                      :disabled="!istongyi ? true : false"
                      @click="chuangjian()"
                      :class="
                      !istongyi
                        ? ' px-12 rounded-pill white--text'
                        : 'primary-background px-12 rounded-pill white--text'
                    "
                    >{{ $t("创建") }}</v-btn>
                  </template>
                  <template v-else>
                    <v-btn
                      height="50"
                      x-large
                      :disabled="!istongyi ? true : false"
                      @click="addShop()"
                      :class="
                      !istongyi
                        ? ' px-12 rounded-pill white--text'
                        : 'primary-background px-12 rounded-pill white--text'
                    "
                    >{{ $t("开通") }}</v-btn>
                  </template>
                  <p v-if="!istongyi" class="red--text text-right mt-2">{{$t('请阅读并同意条款')}}</p>
                </div>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <v-card
        class="mx-auto box transition-fast-in-fast-out"
        style="padding: 156px 0; margin-top: 180px"
        flat
        v-if="tab == 5"
      >
        <v-img width="288" class="mx-auto" src="@/assets/images/login/shengcheng.png"></v-img>
        <v-card width="70%" class="mx-auto mt-6" flat>
          <v-progress-linear
            v-model="knowledge"
            height="16"
            class="rounded-pill progress white--text"
          >
            <strong>{{ Math.ceil(knowledge) }}%</strong>
          </v-progress-linear>
        </v-card>
      </v-card>
    </v-card>
    <v-dialog
      v-model="isshangpin"
      max-width="784"
      height="571"
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card color="#F2F2F2" class="text-h5 rounded-0 py-2 px-5" flat>
          <v-row class="ma-0">
            <div>
              <span class="font18 weight500">{{ $t("商品详情") }}</span>
              <span class="ml-4">{{ $t("共有") }}{{ goodList.length }}{{ $t("个商品") }}</span>
            </div>
            <v-spacer />
            <v-icon size class="cursor" @click="isshangpin = false">mdi-close</v-icon>
          </v-row>
        </v-card>
        <v-card-text class="mt-6 px-2 px-sm-5">
          <v-row class="ma-0">
            <v-chip-group
              @change="searchGood()"
              v-model="checkseries"
              active-class="primary primary--text"
            >
              <v-chip outlined v-for="(item, index) in series_info" :key="index">
                {{ item.name }}
                <span v-if="checkseries==index">({{item.num}})</span>
              </v-chip>
            </v-chip-group>
          </v-row>
          <v-row class>
            <v-col
              cols="6"
              sm="4"
              md="3"
              class="px-2 pl-sm-3"
              v-for="(n, index) in goodList"
              :key="index"
            >
              <v-card flat min-width="150">
                <v-img
                  min-width="150"
                  aspect-ratio="1"
                  :src="
                    n.cover_img == ''
                      ? require('@/assets/images/login/goodnull.png')
                      : n.cover_img
                  "
                ></v-img>
                <p class="text-truncate mb-1 mt-2">{{ n.goods_name }}</p>
                <p class="font18 font-weight-bold mb-0">￥{{ n.price }}</p>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <div class="text-center pa-3" v-if="pagearr.pagetotal > 0">
          <v-pagination
            v-model="pagearr.page"
            :length="pagearr.pagetotal"
            total-visible="5"
            circle
            @input="getpagedata()"
          ></v-pagination>
        </div>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      menu: false,
      keyword: "",
      isshangpin: false,
      tab2Active: {
        name: this.$t("商城站"),
        msg: this.$t(
          "不单是建立了一个网站，而是建立了一个完整的、自动履单免维护，并且可无限扩展升级的事业"
        )
      },
      tab2Arr: [
        {
          name: this.$t("商城站"),
          msg: this.$t(
            "不单是建立了一个网站，而是建立了一个完整的、自动履单免维护，并且可无限扩展升级的事业"
          )
        },
        {
          name: this.$t("代购站"),
          msg: this.$t(
            "创建一个电子商务网站，帮您在线采购中国商品，系统化订单管理，促进销售并管理您的日常工作。"
          )
        }
      ],
      check_moban: "",
      checkseries: 0,
      check_good: "",
      knowledge: 0,
      timer: false,
      tab: 1,
      shopObj: {
        name: "",
        path: "",
        email: "",
        code: "",
        pwd: "",
        phone: "",
        third_web_domain: ""
      },
      istongyi: false,
      goodkuList: [],
      goodList: [],
      series_info: [],
      pagearr: {
        page: 1,
        pagesize: 20,
        pagetotal: 0
      },
      param: {},
      webmsg: this.$t("请填写有效的域名"),
      isshowweb: "error",
      emailmsg: this.$t("请填写正确的邮箱"),
      isshowemail: "error",
      countryList: [],
      cpuntryListOld: [],
      countryObj: "",
      msg: this.$t("点击发送验证码"),
      countNum: 60,
      sendloading: false,
      intervalBtn: "",
      themesList: [],
      iscansendcode: true //点击发送验证码开关 防止重复点击
    };
  },
  mounted() {
    this.getGoodList();
    this.getCountry();
  },
  watch: {
    "$store.state.USERINFO"(val) {}
  },
  methods: {
    inputCountry() {
      if (this.keyword == "") {
        this.countryList = this.countryListOld;
      } else {
        let list = [];
        let lowerKey = this.keyword.toLowerCase();
        for (let item of this.countryListOld) {
          let newVal = item.country + item.mobile_country_code;
          newVal = newVal.toLowerCase();

          if (newVal.includes(lowerKey)) {
            list.push(item);
          }
        }
        this.countryList = list;
      }
    },
    getCountry() {
      this.$api.shop.get_country_list({}).then(res => {
        if (res.status == 1) {
          this.countryList = res.data;
          this.countryListOld = res.data;
        } else {
          this.countryList = [];
          this.countryListOld = [];
        }
      });
    },
    // 倒计时
    countDown() {
      // 设置btn倒计时时显示的信息
      this.msg = null;
      this.iscansendcode = false;
      // 设置倒计时
      this.intervalBtn = setInterval(() => {
        if (this.countNum <= 0) {
          // 重置btn提示信息
          this.msg = this.$t("点击发送验证码");
          // 清除定时器
          this.iscansendcode = true;
          clearInterval(this.intervalBtn);
          // 更改btn状态
          // 重置倒计时状态
          this.countNum = 60;
        }
        // 倒计时
        this.countNum--;
      }, 1000);
    },
    getCode() {
      this.sendloading = true;
      if (this.iscansendcode) {
        this.$api.shop
          .send_code({ account: this.shopObj.email, event: "register" })
          .then(res => {
            this.sendloading = false;
            if (res.status == 1) {
              this.$Toast({ content: res.msg });
              this.countDown();
            } else {
              this.$Toast({ content: res.msg, type: "error" });
            }
          });
      }
    },
    // 获取商品包列表
    getGoodList() {
      this.$api.shop.get_goods_package_list().then(res => {
        if (res.status == 1) {
          this.goodkuList = res.data;
        }
      });
      this.$api.shop.get_themes_list().then(res => {
        if (res.status == 1) {
          this.themesList = res.data;
          this.check_moban = res.data[0];
        }
      });
    },
    checkWeb() {
      const reg = "^[A-Za-z0-9]{4,50}$";
      if (!this.shopObj.path.match(reg)) {
        this.isshowweb = "error";
        this.webmsg = this.$t("商店地址是4-50个字符，字母和数字");
        return false;
      }
      if (this.shopObj.path == "") {
        this.isshowweb = "error";
        this.webmsg = this.$t("请填写有效的域名");
        return false;
      }
      this.$api.shop
        .check_web_domain({
          shop_web_domain: this.shopObj.path + ".taoify.shop"
        })
        .then(res => {
          this.webmsg = res.msg;
          if (res.status == 1) {
            this.isshowweb = "success";
          } else {
            this.isshowweb = "error";
          }
        });
    },
    checkEmail() {
      if (this.shopObj.email == "") {
        this.emailmsg = this.$t("请填写正确的邮箱");
        return false;
      }
      this.$api.shop
        .check_account({ account: this.shopObj.email })
        .then(res => {
          this.emailmsg = res.msg;
          if (res.status == 1) {
            this.isshowemail = "success";
          } else {
            this.isshowemail = "error";
          }
        });
    },
    chuangjian() {
      if (this.shopObj.name == "") {
        this.$Toast({ content: this.$t("请填写店铺名称"), type: "error" });
        return;
      }
      if (this.isshowweb == "error") {
        this.$Toast({
          content: this.$t("请填写有效的店铺域名"),
          type: "error"
        });
        return;
      }
      if (this.isshowemail == "error") {
        this.$Toast({
          content: this.$t("请填写有效的电子邮箱"),
          type: "error"
        });
        return;
      }
      if (this.shopObj.pwd == "") {
        this.$Toast({ content: this.$t("请填写店铺密码"), type: "error" });
        return;
      }
      if (this.shopObj.code == "") {
        this.$Toast({ content: this.$t("请填写邮箱验证码"), type: "error" });
        return;
      }
      if (this.shopObj.phone == "") {
        this.$Toast({ content: this.$t("请填写手机号码"), type: "error" });
        return;
      }
      if (this.countryObj == "") {
        this.$Toast({ content: this.$t("请选择区号"), type: "error" });
        return;
      }
      this.istongyi = false;
      let param = {
        shop_name: this.shopObj.name,
        account: this.shopObj.email,
        password: this.shopObj.pwd,
        shop_web_domain: this.shopObj.path + ".taoify.shop",
        third_web_domain: this.shopObj.third_web_domain,
        goods_package_id: this.check_good.goods_package_id,
        themes_id: this.check_moban.themes_id,
        mobile_country_code: this.countryObj.mobile_country_code,
        mobile: this.shopObj.phone,
        business_country_code: this.countryObj.country_code,
        business_country: this.countryObj.country,
        captcha: this.shopObj.code
      };
      // console.log(param)
      // return
      this.$api.shop.open_shop(param).then(res => {
        if (res.status == 1) {
          localStorage.setItem(
            "DROPSHOPINFO",
            JSON.stringify(res.data.shop_info)
          );
          localStorage.setItem("defaultShopid", res.data.shop_info.shop_id);
          localStorage.setItem("SHOPLIST", JSON.stringify(res.data.shop_infos));
          localStorage.setItem(
            "shop_web_domain",
            res.data.shop_info.shop_web_domain
          );
          this.$store.commit("setUSERINFO", res.data.user_info);
          let deflang_ = {};
          deflang_ = {
            logo: res.data.shop_info.lang_logo,
            lang_sign: res.data.shop_info.lang_sign,
            lang_id: res.data.shop_info.lang_id,
            name: res.data.shop_info.lang_name
          };
          localStorage.setItem("deflang", JSON.stringify(deflang_));
          //  this.getconfig();
          this.tab = 5;
          this.jindu();
        } else {
          this.$Toast({ content: res.msg, type: "error" });
        }
      });
    },
    //添加更多店铺
    addShop() {
      if (this.shopObj.name == "") {
        this.$Toast({ content: this.$t("请填写店铺名称"), type: "error" });
        return;
      }
      if (this.isshowweb == "error") {
        this.$Toast({
          content: this.$t("请填写有效的店铺域名"),
          type: "error"
        });
        return;
      }
      this.istongyi = false;
      let param = {
        shop_name: this.shopObj.name,
        shop_web_domain: this.shopObj.path + ".taoify.shop",
        goods_package_id: this.check_good.goods_package_id,
        themes_id: this.check_moban.themes_id,
        business_country_code: this.countryObj.country_code,
        business_country: this.countryObj.country
      };

      this.$api.shop.add(param).then(res => {
        if (res.status == 1) {
          //  localStorage.setItem('DROPSHOPINFO',JSON.stringify(res.data))
          //  localStorage.setItem('defaultShopid',res.data.shop_id)
          localStorage.setItem(
            "DROPSHOPINFO",
            JSON.stringify(res.data.shop_info)
          );
          localStorage.setItem("defaultShopid", res.data.shop_info.shop_id);
          localStorage.setItem(
            "shop_web_domain",
            res.data.shop_info.shop_web_domain
          );
          localStorage.setItem("SHOPLIST", JSON.stringify(res.data.shop_infos));
          let deflang_ = {};
          deflang_ = {
            logo: res.data.shop_info.lang_logo,
            lang_sign: res.data.shop_info.lang_sign,
            lang_id: res.data.shop_info.lang_id,
            name: res.data.shop_info.lang_name
          };
          localStorage.setItem("deflang", JSON.stringify(deflang_));
          // this.getconfig();
          this.tab = 5;
          this.jindu();
        } else if (res.status == -4) {
          this.$Toast({ content: res.msg, type: "error" });
          location.href = "./manger_admin/sign-up";
        } else {
          this.$Toast({ content: res.msg, type: "error" });
        }
      });
    },
    getconfig() {
      this.$api.public.getconfig().then(res => {
        if (res.status == 1) {
          localStorage.setItem("dropshoplocalconfig", JSON.stringify(res.data));
        }
      });
    },
    getpagedata() {
      this.param.current_page = this.pagearr.page;
      this.getGood();
    },
    searchGood() {
      this.param = {
        goods_package_id: this.param.goods_package_id,
        series_id: this.series_info[this.checkseries].series_id,
        current_page: 1,
        page_number: this.pagearr.pagesize
      };
      this.getGood();
    },
    next(num) {
      if (num == 2 && this.check_good == "") {
        this.$Toast({ content: this.$t("请选择店铺商品"), type: "error" });
        return false;
      }
      if (num == 3 && this.check_moban == "") {
        this.$Toast({ content: this.$t("请选择店铺模板"), type: "error" });
        return false;
      }
      if (num == 4 && this.check_moban == "") {
        this.$Toast({ content: this.$t("请选择店铺模板"), type: "error" });
        return false;
      }
      this.tab = this.tab + 1;
    },
    detail(index) {
      this.pagearr.page = 1;
      this.param = {
        goods_package_id: this.goodkuList[index].goods_package_id,
        series_id: -1,
        current_page: this.pagearr.page,
        page_number: this.pagearr.pagesize
      };
      let series_info = [
        {
          name: this.$t("全部"),
          id: -1
        }
      ];
      series_info = series_info.concat(this.goodkuList[index].series_info);
      this.series_info = series_info;
      this.getGood();
    },
    getGood() {
      this.$api.shop.get_goods_package_info_list(this.param).then(res => {
        if (res.status == 1) {
          this.isshangpin = true;
          this.goodList = res.data.records;
          this.series_info[this.checkseries]["num"] = res.data.total;
        } else {
          this.goodList = [];
          this.$Toast({ content: this.$t("暂无商品"), type: "error" });
        }
        this.pagearr.pagetotal = Math.ceil(
          parseInt(res.data.total) / this.pagearr.pagesize
        );
      });
    },
    jindu() {
      this.knowledge = 0;
      this.timer = setInterval(() => {
        if (this.knowledge == 100) {
          this.shopObj.path;
          if (this.$store.state.USERINFO != null) {
            this.shopObj.email = this.$store.state.USERINFO.email;
          }
          let obj = {
            shop: this.shopObj,
            good: this.check_good,
            modie: this.check_moban
          };

          //   obj.shop.path = obj.shop.path + ".taoify.shop";
          localStorage.setItem("shop", JSON.stringify(obj));
          this.$router.push({
            path: "/success"
          });
          return false;
        }
        this.knowledge = this.knowledge + 5;
      }, 300);
      if (this.knowledge == 100) {
        clearInterval(this.timer);
      }
    }
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.intervalBtn);
  }
};
</script>

<style lang="scss" scoped>
.box {
  width: 1000px;
}
@media (max-width: 1000px) {
  .box {
    width: 80%;
  }
}
@media (max-width: 500px) {
  .box {
    width: 95%;
  }
}
.overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
}
.ipt-href {
  border: solid 1px #e5e5e5;
  height: 45px;
  background-color: #f7f7f7;
  border-radius: 4px;
}
.ipt-href-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: solid 1px #e5e5e5;
  height: 43px;
  background-color: #ffffff;
}
.ipt-href-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: solid 1px #e5e5e5;
  height: 43px;
  background-color: #ffffff;
}
</style>